.logo-img img{
  background-color: #ffffff;
  border-radius: 6px;
  padding: 9px;
}
.invalid-feedback{
  font-size: 12px;
}
label:not([for]) {
  width: 100%;
  margin-bottom: 0;
}
cron-jobs label{
  width: auto !important;
}
label:not([for]):first-child {
  margin-top: 0.2rem;
}

.card .header {
  background: #009cc2;
  border-radius: 6px 6px 0 0;
  position: relative;
}
.card .header h6 {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
  padding: 12px;
}
.card .header .btn-add {
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}
.card .header .btn-search-engine-reload {
  display: none;
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 30px;
  color: white;
  cursor: pointer;
  font-size: 24px;
  top: 10px;
}
.card .header .accordion-button-up {
  display: none;
  position: absolute;
  top: 7px;
  left: 10px;
  font-size: 30px;
  color: white;
  cursor: pointer;
}
.card-body{clear: both;padding: 1rem;}
.btn-primary{
  background-color: #009cc2 !important;
  border-color: #009cc2 !important;
}
.custom-control-input:checked ~ .custom-control-label::before{
  background-color: #009cc2 !important;
  border-color: #009cc2 !important;
}
.app-sidebar{
  overflow: hidden;
}
.app-sidebar.app-bg-image{
  /*background-image: url(assets/img/sidebar_bg.960143615c7a6718.png);*/
  background-repeat: no-repeat;
  background-size: cover;
  background: #009cc2;
}
.app-sidebar:before {
  background: rgba(255, 255, 255, 0) !important;
  opacity: 0.0 !important;
}
button.btn-float-right{float: right; margin-right: 0px !important;}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked{background-color: #009cc2a6 !important;}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option-selected{background-color: #009cc2 !important;}
.ng-select.ng-select-opened > .ng-select-container{border-color: #009cc2 !important;}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {  background-color: #009cc2;}
.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container{border-color: #009cc2}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label,
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon{
  border-color: #009cc2;
}

.btn-link{color: #009cc2;}
.app-sidebar:before {background: #000000; opacity: 0.4}
.dropdown .dropdown-menu .dropdown-item:active, .dropup .dropdown-menu .dropdown-item:active, .dropright .dropdown-menu .dropdown-item:active, .dropleft .dropdown-menu .dropdown-item:active{
  background-color: #009cc2 !important;
}
.menu-sep{
  border-top: 4px solid rgb(237 239 243 / 28%) !important; margin-top: 20px;
}
.ng-image-slider .ng-image-slider-container .main .main-inner .img-div.image-popup,
.ng-image-slider .ng-image-slider-container .main .main-inner .img-div{
  box-shadow: none !important;
}

.menu-back-bo{text-align: center; width: 100%;position: absolute; bottom: 55px;}
.menu-back-bo-v2{text-align: center; width: 100%;position: absolute; bottom: 15px; z-index: 99}
.menu-back-bo-v2-mobile{display: none !important;}
.menu-back-bo-btn{
  width: 100%;
  margin: auto;
  background-color: white;
  border-radius: 20px;
  padding: 10px 1px;
  overflow: hidden;
  white-space: break-spaces;
  font-size: 12px;
  font-weight: bold;
  max-width: 130px;
  color: black;
}
.menu-back-bo-btn img{
  width: 60px;
  margin-bottom: 10px;
}
.menu-back-bo-btn-v2{
  width: 98%;
  max-width: 150px;
  margin: auto;
  background-color: white;
  border-radius: 10px;
  padding: 9px 7px 7px;
  overflow: hidden;
  white-space: break-spaces;
  font-size: 12px;
  font-weight: bold;
  color: black;
  text-align: center;
}
.menu-back-bo-btn-v2 img{
  width: 22px;
  margin-right: 10px;
  margin-top: -4px;
}
body.menu-open.overflow-hidden .menu-back-bo-v2{display: none}
body.menu-open.overflow-hidden .menu-back-bo-v2-mobile{display: block !important;}
.app-sidebar.menu-native-scroll .main-menu-content{padding-bottom: 100px}

/* *****TABLES***** */
.table .thead-light th{background-color: #d9d9d9 !important;border-bottom: 0;
  font-family: Montserrat,serif!important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  /*text-align: center!important;*/
}
.table tr{background: none !important;}
.table td{
  font-family: Montserrat,serif!important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  background: none !important;
}
.table td, .table th{padding: 10px 10px; vertical-align: baseline}
.table i.fa{font-size: 18px!important;margin: 5px 7px !important;}
.table i[class*=" ft-"], .table i[class^=ft-]{font-size: 18px!important;margin: 5px 7px!important;}
/* END TABLES */
/* ***** BREACRUMB ***** */
.t-breacrumb{
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 14px;
}
.t-breacrumb--item {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: #000;
}
.t-breacrumb--item:not(:last-child):after {
  content: "";
  width: 20px;
  height: 20px;
  background: url(assets/img/arrow.9f80e64ace57eadb.png);
  display: inline-block;
  background-size: cover;
  margin: 0 4px;
}

app-ng-dynamic-breadcrumb ul{
  background-color: transparent !important;
  margin-bottom: 1.2rem !important;
  margin-top: -0.1rem !important;
  padding: 0 !important;
}
app-ng-dynamic-breadcrumb ul li {
  font-weight: 500;
  color: #000 !important;
  list-style: none !important;
  font-size: 14px !important;
}
app-ng-dynamic-breadcrumb ul li span.line { display: none}
app-ng-dynamic-breadcrumb ul > span >  li > a {
  color: unset !important;
  /*text-decoration: underline !important;*/
}
/* *****END BREACRUMB***** */
/* *****PAGINATION***** */
.page-item.active .page-link{
  background-color: #009cc2 !important;
  border-color: #009cc2;
}
.page-link,.page-link:hover{color: #009cc2;font-family: Montserrat,serif!important;font-size: 12px;font-weight: 500;min-width: 32px;
  text-align: center;}
/* *****END PAGINATION***** */

/* *****INPUT***** */

.form-control{

  line-height: normal;
  border: 1px solid #c5c1c1;
  color: #000;
  padding: 8px 10px;
  height: 37px;
  font-weight: 500;
  font-family: Montserrat;
  font-size: 12px;
}
form label, .form-group label, span.label{font-size: 14px !important; text-transform: none}
 span.label{
   font-weight: 500;
   letter-spacing: 0.5px;
   display: inline-block;
 }
.form-control:focus {border-color: #009cc2 !important;}
.form-control:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #bfdbfe;
  border-color: #3b82f6;
}
.ng-select, .ng-select .ng-select-container,.ng-select.ng-select-single .ng-select-container{
  font-size: 14px;height: calc(1.5em + 0.65rem + 0px); min-height: unset;height: 37px;}
.ng-select .ng-option{font-size: 14px ;
  padding: 4px 10px !important;}
.ng-select .ng-clear-wrapper{top: 2px}
/* *****INPUT***** */
.user span{line-height: 2.1rem;}
.input-group-textx{height: calc(1.5em + 0.65rem + -1px);}
.btn.disabled, .btn:disabled {
  opacity: 0.25;
  cursor: default;
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1300px;
  }
}
@media (max-width: 1200px) {
  select.form-control{
    padding: 8px 3px !important;
  }
  .fit-content-select select{
    width: fit-content !important;
  }
  .card .header h6 {
    padding: 12px 12px 12px 32px;
  }
  .card .header h6.no-engine {
    padding: 12px 12px 12px 12px;
  }
  .card .header .accordion-button-up {
    display: block;
  }
  .card .header .btn-search-engine-reload {
    display: block;
  }
  .card .header .btn-add.with-reload {
    right: 40px;
  }
}
.fa-purge-info:before{font-size: 5px}
.ft-play:before {
  font-size: 14px !important;
}


.modal .table.table-striped.sql-table th{
  font-size: 12px;
  padding: 5px;
  max-width: 300px;width: 10px;
}
.modal .table.table-striped.sql-table td{
  font-size: 10px;
  padding: 2px 5px;
}
.modal .table.table-striped.sql-table td pre{
  font-size: 10px;
  font-family: Montserrat,serif!important;
  margin-bottom: 0;
}

.token.selector, .token.attr-name, .token.string, .token.char, .token.builtin, .token.inserted {
  color: red !important;
}
.token.atrule, .token.attr-value, .token.keyword{
  color: #124EEC !important;
}
.token.operator{
  color: grey !important;
}
.token.variable {
  color: #675555 !important;
}
.token.punctuation {
  color: #000000;
}
.modal .textarea-content.sql-content pre code{
  font-family: Montserrat,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
  font-size: 12px;
  font-weight: 500;
}
/********************************************************/
.modal-overview-show .modal-content{overflow: visible}
.no-data-available{text-align: center}
.custom-switch{padding-top: 8px}
.visually-hidden{display: none !important; visibility: hidden}
.ngb-tp-input-container.ngb-tp-hour,
.ngb-tp-input-container.ngb-tp-minute
{position: relative}
.ngb-tp-input-container.ngb-tp-hour button,
.ngb-tp-input-container.ngb-tp-minute button
{
  box-shadow:none;
  position: absolute;
  width: 16px;
  margin: 0 !important;
  padding: 0;
  right: 0;
  transform: rotateY(0deg) rotate(90deg);
  top: 2px;
}
.ngb-tp-input-container.ngb-tp-hour button:last-child,
.ngb-tp-input-container.ngb-tp-minute button:last-child{
  right: auto; left: 0;

}
.ngb-tp-input{height: auto}
ngb-timepicker{margin-top: 1px}
.app-sidebar .navigation i{
  font-size: 1.0rem;
  width: inherit;
}
.app-sidebar .navigation i.submenu-icon{
  font-size: 1.2rem;
  width: inherit;
}
.app-sidebar .navigation li ul li ul li a,
.app-sidebar .navigation li ul li a {
  /*padding: 1px 18px 1px 60px;*/
}

ngb-pagination {float: right}

/*!* Chrome, Safari, Edge, Opera *!*/
input::-webkit-outer-spin-button,
 input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
 }

/*!* Firefox *!*/
 input[type=number] {
   -moz-appearance: textfield;
 }
.app-sidebar .navigation li ul li a{/*padding-left: 30px*/}

.visually-hidden{display: none !important; visibility: hidden}

.form-control.form-label{
  height: 21px;
  margin-bottom: 0.2rem !important;
  color: #342E49;
}
.red{color: red}
.no-data-available{text-align: center}

.ngb-tp-chevron:before{left: 5px !important;}
.modal-body {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.myTooltip .tooltip-inner{ max-width: 400px;}

.tooltip-template pre{
  color: white;
  margin-bottom: 0;
  text-align: left;
  font-family: "Montserrat", "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}


.table tr.transaction-success{
  background-color: #40C0571a !important;
}
.table tr.transaction-warning{
  background-color: #ff980036 !important;
}
.table tr.transaction-error{
  background-color: #ff002436 !important;
}

.table tr.transaction-success.current-log{
  background-color: #40C05750 !important;
}
.table tr.transaction-warning.current-log{
  background-color: #ff980060 !important;
}
.table tr.transaction-error.current-log{
  background-color: #ff002460 !important;
}
/*.table tr.current-log td{background-color: #e9ecef !important;}*/
.ng-select-disabled{background-color: #f7f7f7}
/********************************************************/

/* ADAPTATION BO */
.sidebar-lg.menu-expanded .app-sidebar, .sidebar-lg.menu-open .app-sidebar{width: 300px}
.vertical-menu.sidebar-lg .navbar, .vertical-menu.sidebar-lg .main-panel .main-content{
  margin-left: 285px;
}
.sidebar-header {
  margin-bottom: 20px;
}
.sidebar-server {
  text-align: center;
  font-size: 16px;
  background: white;
  width: -moz-fit-content;
  width: fit-content;
  margin: 8px auto auto;
  padding: 2px 10px;
  border-radius: 8px;
  color: green;
}
.sidebar-server-PRODUCTION{
  color: red;
}
app-ng-dynamic-breadcrumb ul > span:not(:last-child) li:after {

  content: "";
  width: 20px;
  height: 20px;
  background: url(assets/img/arrow.9f80e64ace57eadb.png);
  display: inline-block;
  background-size: cover;
  margin: -6px 4px;
}
.app-sidebar .sidebar-content {
  position: relative;
  height: calc(100vh - 62px)!important;
  height: calc(var(--vh, 1vh) * 100 - 62px)!important;
  z-index: 4;
  padding: 0 16px 0 10px;
}
.menu-active {
  background-color: #e0e0e033!important;
}
body .app-sidebar .navigation li > a:before{display: none}
.app-sidebar .navigation i {
  font-size: 1.2rem;
  font-weight: 500;
  float: left;
  margin-right: 5px;
  line-height: 2rem;
  width: 27px;
  text-align: center;
  opacity: 1;
}
.app-sidebar .navigation li ul li a {
  display: block;
  padding: 8px 18px 8px 30px;
}
body[data-menu=vertical-menu] .app-sidebar .navigation li.active > a:before{
  content: "";
  display: block;
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-right: solid 5px #B02940;
  border-right: solid 5px #C90D7D;
  background: rgba(224,224,224,.2);
  transition: .35s ease left;
}

.app-sidebar .navigation .menu-title {
  margin: 0;
  font-size: .95rem;
  font-weight: 600;
  letter-spacing: 1px;
  opacity: 1;
  height: auto;
  transition: all .3s cubic-bezier(0,0,.2,1);
}
.app-sidebar .navigation li>a {
  display: block;
  line-height: 2rem;
  padding: 10px 30px 10px 10px;
  margin: 5px 0;
  background-color: transparent;
  color: #fff;
  transition: all .3s linear;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
